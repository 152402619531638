<template>
	<div>
		<b-card>
			<div class="list-view" v-on:click="hatcherView()" style="cursor: pointer">
				<b-row>
					<b-col md="6">
						<div class="d-flex justify-content-between">
							<div>
								<span class="head-text">
									{{ hatcherName }}
									<span class="success-text"> ({{ deviceName }})</span>
								</span>
							</div>
							<div>
								<span class="mr-1">
									<b-img
										:src="require('@/assets/images/svg/machine/temprature.svg')"
										style="height: 26px; width: 26px"
									/>
								</span>
								<span class="head-text"
									>{{ temperature }}
									<sup
										style="color: #f6667e; font-size: 10px; margin-right: 3px"
										>0</sup
									><span class="red-text">c</span></span
								>
							</div>
							<div>
								<span class="mr-1">
									<b-img
										:src="require('@/assets/images/svg/machine/humidity.svg')"
										style="height: 26px; width: 26px"
									/>
								</span>
								<span class="info-text"
									>{{ humidity }}

									<span style="font-size: 12px">%</span></span
								>
							</div>
						</div>
					</b-col>
					<b-col md="6 d-flex justify-content-end">
						<!-- <span v-bind:class="[active ? 'activeClass' : 'inactiveClass']"
							>Active</span
						>
						<span v-bind:class="[active ? 'inactiveClass' : 'activeClass']"
							>Inactive</span
						> -->

						<div v-if="status === 'Active'" class="activeClass">Active</div>
						<div v-else class="inactiveClass">Inactive</div>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span
									><b-img
										:src="require('@/assets/images/svg/machine/egg.svg')"
										style="margin-right: 10px" /></span
								>Available Eggs
							</div>
							<div class="success-text">{{ availableEgg }}</div>
						</div>
					</b-col>
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span>
									<b-img
										:src="require('@/assets/images/svg/machine/available.svg')"
										style="height: 17px; width: 17px"
										class="mr-2" /></span
								>Tray Capacity
							</div>
							<div class="head-text">{{ trayCapacity }}</div>
						</div>
					</b-col>
					<b-col md="4">
						<div class="d-flex align-items-center">
							<div class="mr-4 head-text">
								<span
									><b-img
										:src="require('@/assets/images/svg/machine/occupied.svg')"
										style="height: 17px; width: 17px"
										class="mr-2" /></span
								>Egg Capacity
							</div>
							<div class="head-text">{{ eggCapacity }}</div>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,
		vSelect,
		BImg,
	},
	props: [
		"id",
		"hatcherName",
		"hatchery_device_id",
		"humidity",
		"temperature",
		"availableEgg",
		"trayCapacity",
		"eggCapacity",
		"status",
		"deviceName",
	],
	methods: {
		hatcherView() {
			this.$router.push({
				name: `apps-machine-hatcher-view`,
				query: {
					machineId: this.id,
					 hatchery_device_id: this.hatchery_device_id,
				},
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.list-view {
	background-color: #fafafa;
	box-shadow: 0px 3px 6px #38c06c33;
	height: 100%;
	padding: 1rem 1rem;
	margin: auto;
}
.head-text {
	font-size: 18px;
	color: #1d1d1d;
}
.red-text {
	color: #f6667e;
	font-size: 10px;
}
.info-text {
	color: #1c54e4;
	font-size: 19px;
}
.success-text {
	color: #38c06c;
	font-size: 15px;
}
.svg-img {
	width: 17px;
	height: 17px;
	// margin-right: 10px
}
.activeClass {
	background-color: #38c06c;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 54px;
	height: 23px;
	border-radius: 4px;
	justify-content: center;
}
.inactiveClass {
	background-color: #dd2e61;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 54px;
	height: 23px;
	justify-content: center;
	border-radius: 4px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
